export default {
    ADD_ITEM(state, item) {
        state.TapPayments.unshift(item)
    },
    SET_TapPayment(state, TapPayments) {

        state.TapPayments = TapPayments
    },
    UPDATE_TapPayment(state, TapPayment) {
        const TapPaymentIndex = state.TapPayments.findIndex((p) => p.ID == TapPayment.ID)
        Object.assign(state.TapPayments[TapPaymentIndex], TapPayment)
    },
  
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.TapPayments.findIndex((p) => p.ID == itemId)
        state.TapPayments.splice(ItemIndex, 1)
    },
}
import axios from "@/axios.js"

export default {
    AddTapPayment({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/TapPayment/AddTapPayment", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllTapPayments({ commit }, search) {
        return new Promise((resolve, reject) => {
            axios.post("api/TapPayment/SearchTapPayment", search).then((response) => {
                    commit('SET_TapPayment', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    UpdateTapPayment(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("api/TapPayment/UpdateTapPayment", item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },




    GetTapPayment({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.get("api/TapPayment/GetTapPayment?ID=" + itemid)
                .then((response) => {
                    debugger
                    //commit('UPDATE_TapPayment', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteTapPayment({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/TapPayment/DeleteTapPayment?ID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}
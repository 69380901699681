<template>
  <div id="data-list-list-view">
    <vs-table
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="TapPayments"
    >
      <div
        slot="header"
        class="flex mb-12 flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="addNewData"
          >{{ $t("AddNew") }}</vs-button
        > -->

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                TapPayments.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : TapPayments.length
              }}
              of {{ TapPayments.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead" >
         <vs-th sort-key="MarkupServiceType">{{ $t("Order ID" ) }}</vs-th>
         <vs-th sort-key="MarkupServiceType">{{ $t("Amount" ) }}</vs-th>
         <!-- <vs-th sort-key="MarkupServiceType">{{ $t("User Name" ) }}</vs-th> -->
         <vs-th sort-key="MarkupServiceType">{{ $t("Status Name" ) }}</vs-th>
         <vs-th sort-key="MarkupServiceType">{{ $t("Appointment" ) }}</vs-th>
         <!-- <vs-th sort-key="MarkupServiceType">{{ $t("Surgery" ) }}</vs-th> -->
         <!-- <vs-th sort-key="MarkupServiceType">{{ $t("Extra Service" ) }}</vs-th> -->

        <!-- <vs-th>{{ $t("Action") }}</vs-th> -->
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate" >{{tr.OrderID}} </p>
            </vs-td>
             <vs-td>
              <p class="product-name font-medium truncate" >{{tr.Amount}} </p>
            </vs-td>
               <!-- <vs-td>
              <p class="product-name font-medium truncate" >{{tr.UserName}} </p>
            </vs-td> -->
             <vs-td>
              <p class="product-name font-medium truncate" >{{tr.StatusName}} </p>
            </vs-td>
             <vs-td>
              <p class="product-name font-medium truncate" >{{tr.PatientReservationSessionName}} </p>
            </vs-td>
             <!-- <vs-td>
              <p class="product-name font-medium truncate" >{{tr.PatientReservationSurgeryName}} </p>
            </vs-td>
             <vs-td>
              <p class="product-name font-medium truncate" >{{tr.PatientReservationSurgeryExtraServiceName}} </p>
            </vs-td> -->
            <!-- <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="EditIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editData(tr)"
              />
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click="openConfirm(tr)"
              />
            </vs-td> -->
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleTapPayment from "@/store/TapPayment/moduleTapPayment.js";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      itemsPerPage: 10,
      selected: [],
      search: {},
    };
  },
  methods: {
      TapPaymentsearch(search)
      {
          this.$store.dispatch("TapPaymentList/GetAllTapPayments",search);
      },
    addNewData() {
      this.$router.push("TapPaymentAddEdit");
    },
    editData(tr) {
      this.$router.push("TapPaymentAddEdit/" + tr.ID);
    },
    deleteData(data) {
      this.$store
        .dispatch("TapPaymentList/DeleteTapPayment", data)
        .catch((err) => {
          console.error(err);
        });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert,
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 1;
    },

    TapPayments() {
      return this.$store.state.TapPaymentList.TapPayments;
    },
  },
  created() {
    if (!moduleTapPayment.isRegistered) {
      this.$store.registerModule("TapPaymentList", moduleTapPayment);
      moduleTapPayment.isRegistered = true;
    }
    debugger
    var obj={};
    obj.IsAppointment = true;
    obj.IsSurgery = true;

    obj.IsExtraService = true;

  this.TapPaymentsearch(obj);

  },
};
</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
